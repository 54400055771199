<template>
  <v-row no-gutters>

    <!-- Service -->
    <v-col cols="12">
      <v-text-field
        :label="$t('common.address.service')"
        outlined
        v-model="form.service"
        maxlength="100">
      </v-text-field>
    </v-col>

    <!-- Street -->
    <v-col cols="12">
      <v-text-field
        :label="$t('common.address.street')"
        outlined
        v-model="form.street"
        maxlength="255">
      </v-text-field>
    </v-col>

    <!-- Additional Street -->
    <v-col cols="12">
      <v-text-field
        :label="$t('common.address.additional_street')"
        outlined
        v-model="form.additional_street"
        maxlength="255">
      </v-text-field>
    </v-col>

    <!-- Locality -->
    <v-col cols="12">
      <v-text-field
        :label="$t('common.address.city')"
        outlined
        v-model="form.city"
        maxlength="255">
      </v-text-field>
    </v-col>

    <!-- Area -->
    <v-col cols="12">
      <v-text-field
        :label="$t('common.address.administrative_area')"
        outlined
        v-model="form.administrative_area"
        maxlength="255">
      </v-text-field>
    </v-col>

    <!-- Postal Code -->
    <v-col cols="12">
      <v-text-field
        :label="$t('common.address.postal_code')"
        outlined
        v-model="form.postal_code"
        maxlength="40">
      </v-text-field>
    </v-col>

    <!-- Country -->
    <v-col cols="12">
      <SelectCountries
        :multiple="false"
        :value="form.country"
        :enableClearable="isCountryDeletable"
        @input="(val) => {form.country = val;}"/>
    </v-col>

    <!-- Region --
    <v-col cols="12">
      <v-text-field
      :label="$t('common.address.region')"
      outlined
      v-model="form.region"
      maxlength="200">
    </v-text-field>
    </v-col-->
  </v-row>
</template>

<script>
  // Mixins
  import formRulesMixin from '@/mixins/formRules';

  export default {
    name: "AddressForm",

    mixins: [formRulesMixin],

    props: {
      isCountryDeletable: { type: Boolean, default: false },
      form: { type: Object, default: () => {} }
    },

    components: {
      SelectCountries: () => import("@/components/Common/Inputs/SelectCountries")
    },

    data() {
      return {
      }
    }

  }
</script>

<style scoped>

</style>
